import React from "react";
import Carousel from "./Carousel.js";

export default function MyCarousel() {
    const images = [
        './assets/slides/belfast-city-hall.jpg', 
        './assets/slides/harland-and-wolff.jpeg', 
        './assets/slides/peace-bridge.jpg', 
        './assets/slides/stairway-to-heaven.jpg', 
        './assets/slides/giants-causeway.jpg', 
        './assets/slides/armagh-city.jpeg', 
        './assets/slides/mourne-mountains.jpg', 
        './assets/slides/omagh-town-centre.jpg', 
        './assets/slides/scarbo-tower.jpeg'
    ];

    const imageAlts = [
        'Belfast City Hall', 
        'Harland And Wolff Cranes', 
        'Peace Bridge', 
        'Stairway To Heaven', 
        'Giants Causeway', 
        'Armagh City', 
        'Mourne Mountains', 
        'Omagh Town Centre', 
        'Scarbo Tower'
    ];

    const getImages = () => {
        let ImageObjects = [];

        for (let index = 0; index < images.length; index++) {
            let image = images[index];
            let imageAlt = imageAlts[index];
            ImageObjects.push(
                <div><img className="carousel-img" alt={imageAlt} src={require(`${image}`)}/></div>
            )
        }

        return ImageObjects;
    }

    return (
        <Carousel>
            {getImages()}
        </Carousel>
  );
}