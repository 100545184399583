import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//images
import engineIcon from './assets/icons/engine.png';
import transmissionIcon from './assets/icons/transmission.png';
import brakesIcon from './assets/icons/brakes.png';
import coolingIcon from './assets/icons/cooling2.png';
import dieselIcon from './assets/icons/diesel.png';
import pertrolIcon from './assets/icons/petrol.png';
import specialityIcon from './assets/icons/star.png';

const Products = () => {
    return (
        <div style={{color: 'white', padding: '5px', marginBottom: '10px', marginTop: '10px'}}>
            <Container>
                <Row>
                    <Col 
                        onClick={() => {window.open('https://www.bgprod.com/catalog/category/engine/')}} 
                        style={{marginRight: '10px', background: '#444444', border: '2px solid #444444', borderRadius: '4px'}}
                    >
                        <Row>
                            <Col>
                                <img style={{height: '100px', margin:'auto', display:'block'}} src={engineIcon} alt={'Engine'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{fontSize:'20px', textAlign:'center'}}>
                                Engine
                            </Col>
                        </Row>
                    </Col>
                    <Col 
                        onClick={() => {window.open('https://www.bgprod.com/catalog/category/transmission/')}} 
                        style={{marginRight: '10px', background: '#444444', border: '2px solid #444444', borderRadius: '4px'}}
                    >
                        <Row>
                            <Col>
                                <img style={{height: '100px', margin:'auto', display:'block'}} src={transmissionIcon} alt={'Transmission'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{fontSize:'20px', textAlign:'center'}}>
                                Transmission
                            </Col>
                        </Row>
                    </Col>
                    <Col 
                        onClick={() => {window.open('https://www.bgprod.com/catalog/category/brakes/')}} 
                        style={{marginRight: '10px', background: '#444444', border: '2px solid #444444', borderRadius: '4px'}}
                    >
                        <Row>
                            <Col>
                                <img style={{height: '100px', margin:'auto', display:'block'}} src={brakesIcon} alt={'Brakes'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{fontSize:'20px', textAlign:'center'}}>
                                Brakes
                            </Col>
                        </Row>
                    </Col>
                    <Col 
                        onClick={() => {window.open('https://www.bgprod.com/catalog/category/cooling-system/')}} 
                        style={{marginRight: '10px', background: '#444444', border: '2px solid #444444', borderRadius: '4px'}}
                    >
                        <Row>
                            <Col>
                                <img style={{height: '100px', margin:'auto', display:'block'}} src={coolingIcon} alt={'Cooling'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{fontSize:'20px', textAlign:'center'}}>
                                Cooling
                            </Col>
                        </Row>
                    </Col>
                    <Col 
                        onClick={() => {window.open('https://www.bgprod.com/catalog/category/diesel-fuel-system/')}} 
                        style={{marginRight: '10px', background: '#444444', border: '2px solid #444444', borderRadius: '4px'}}
                    >
                        <Row>
                            <Col>
                                <img style={{height: '100px', margin:'auto', display:'block'}} src={dieselIcon} alt={'Diesel'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{fontSize:'20px', textAlign:'center'}}>
                                Diesel
                            </Col>
                        </Row>
                    </Col>
                    <Col 
                        onClick={() => {window.open('https://www.bgprod.com/catalog/category/gasoline-fuel-system/')}} 
                        style={{marginRight: '10px', background: '#444444', border: '2px solid #444444', borderRadius: '4px'}}
                    >
                        <Row>
                            <Col>
                                <img style={{height: '100px', margin:'auto', display:'block'}} src={pertrolIcon} alt={'Petrol'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{fontSize:'20px', textAlign:'center'}}>
                                Petrol
                            </Col>
                        </Row>
                    </Col>
                    <Col 
                        onClick={() => {window.open('https://www.bgprod.com/catalog/category/specialty/')}} 
                        style={{background: '#444444', border: '2px solid #444444', borderRadius: '4px'}}
                    >
                        <Row>
                            <Col>
                                <img style={{height: '100px', margin:'auto', display:'block'}} src={specialityIcon} alt={'Speciality'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{fontSize:'20px', textAlign:'center'}}>
                                Speciality
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Products;