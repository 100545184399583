import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//images
import star1 from './assets/icons/star1.png';
import star2 from './assets/icons/star2.png';
import star3 from './assets/icons/star3.png';
import star4 from './assets/icons/star4.png';
import star5 from './assets/icons/star5.png';

const Reviews = () => {
    const companies = ['MO Autorepairs'];
    const companyLogos = ['./assets/logos/mo-auto-repairs.jpg'];
    const websites = ['https://moautorepairs.co.uk/'];
    const reviews = [
        "Shelf stocked up again with the latest delivery of the BG products. We are now using this in every service. We are seeing great results with these products and customers even commenting that there cars are running alot smoother and more responsive. We pride ourselves on using the best products on your vehicles"
        
    ];
    const ratings = [5, 5, 5, 5, 5];

    const getReviews = () => {
        let reviewObjects = [];

        for (let index = 0; index < companies.length; index++) {
            const company = companies[index];
            const companyLogo = companyLogos[index];
            const website = websites[index];
            const review = reviews[index];
            const rating = ratings[index];
            
            reviewObjects.push(
                <div style={{color: 'white', background: '#444444', border: '2px solid #444444', padding: '5px', borderRadius: '4px', marginBottom: '10px', marginTop: '10px', marginRight: '10px', height: '100%'}}>
                    <Container>
                        <Row>
                            <Col>
                                <a href={website}><img src={require(`${companyLogo}`)} alt={company + ' company logo'}/></a>
                            </Col>
                            <Col>
                                {company}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <a href={website}>{website}</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{fontSize:'12px'}}>
                                {review}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <img alt={rating + ' star review'} style={{height: '30px'}} src={ rating === 5 ? star5 : rating === 4 ? star4 : rating === 3 ? star3 : rating === 2 ? star2 : star1 }/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }

        return reviewObjects;
    }

    return (getReviews())
}

export default Reviews;