const BgImages = () => {
    const images = ['./assets/products/IMG-20240909-WA0001.jpg', './assets/products/IMG-20240909-WA0002.jpg', './assets/products/IMG-20240909-WA0003.jpg', './assets/products/IMG-20240909-WA0004.jpg', './assets/products/IMG-20240909-WA0005.jpg', './assets/products/IMG-20240909-WA0006.jpg', './assets/products/IMG-20240909-WA0007.jpg', './assets/products/IMG-20240909-WA0008.jpg', './assets/products/IMG-20240909-WA0009.jpg', './assets/products/IMG-20240909-WA0010.jpg', './assets/products/IMG-20240909-WA0011.jpg', './assets/products/IMG-20240909-WA0012.jpg', './assets/products/IMG-20240909-WA0013.jpg', './assets/products/IMG-20240909-WA0014.jpg', './assets/products/IMG-20240909-WA0015.jpg', './assets/products/IMG-20240909-WA0016.jpg'];
    const imageAlts = ['Products On Shelf', 'Products', 'Inject-a', 'Products On Shelf', 'Products On Shelf', 'Products On Shelf', 'Products On Shelf', 'Products On Shelf', 'Products On Shelf', 'Products On Shelf', 'Products On Shelf', 'Products On Shelf', 'Products On Shelf', 'Products On Shelf', 'Products On Shelf', 'Products On Shelf'];

    const getImages = () => {
        let ImageObjects = [];

        for (let index = 0; index < images.length; index++) {
            let image = images[index];
            let imageAlt = imageAlts[index];
            ImageObjects.push(
                <img alt={imageAlt} style={{height: '200px', marginBottom: '10px' }} src={require(`${image}`)}/>
            )
        }

        return ImageObjects;
    }

    return (getImages())
}

export default BgImages;