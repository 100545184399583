
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, {useState, useEffect, useRef} from 'react';
import emailjs from "@emailjs/browser";

import './App.css';
import './Navbar.css';
import Products from './Products';
import Reviews from './Reviews';
import BgImages from './BgImages';
import BgVideos from './BgVideos';
import MyCarousel from './MyCarousel';

//images
import logo from './assets/logo.svg';
import emailIcon from './assets/icons/email-icon.png';
import phoneIcon from './assets/icons/phone-icon.png';
import facebookIcon from './assets/icons/Facebook_Logo.png';
import instagramIcon from './assets/icons/Instagram_icon.png';
import xIcon from './assets/icons/x_icon.png';
import youtubeIcon from './assets/icons/youtube_icon.png';

const App = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [request, setRequest] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const backgroundColor = useRef('#FFDC00');
  const [, setBackgroundColor] = useState('#FFDC00');

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const videoResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const imagesResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 10
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const serviceId = 'service_r4kev9j';
    const templateId = 'template_qgioeby';
    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        name: fullName,
        phone: phone,
        email: email,
        request: request,
        message: message,
        address: address
      });
      alert('Your Inquiry was successfully sent to BG Products NI');
    } catch (error) {
      console.log(error);
    } finally {
      setAddress('');
      setEmail('');
      setFullName('');
      setMessage('');
      setPhone('');
      setRequest('');
      setLoading(false);
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if(backgroundColor.current === '#FF9900'){
        backgroundColor.current = '#FFDC00';
        setBackgroundColor('#FFDC00');
      }
      else{
        backgroundColor.current = '#FF9900';
        setBackgroundColor('#FF9900');
      }
    }, 1000)
    
    return () => clearInterval(intervalId);
  }, [])

  useEffect(() => emailjs.init('kBgxfOIrQF9NNksNX'), []);

  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
        <Container>
            <Navbar.Brand href="#home">
              <img
                alt="BG Products NI"
                src={logo}
                height="40"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Nav.Link href='tel:+447872545149' style={{marginRight: '20px'}}>+447872545149</Nav.Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href='#home'>Home</Nav.Link>
                  <Nav.Link href='#products'>Products</Nav.Link>
                  <Nav.Link href='#reviews'>Reviews</Nav.Link>
                  <Nav.Link href='#contactUs'>Contact Us</Nav.Link>
                  <Nav.Link onClick={() => {window.open('https://www.facebook.com/profile.php?id=61558511546994')}}>
                    <img
                      src={facebookIcon}
                      width='20px'
                      height='20px'
                      className='d-inline-block align-top'
                      alt='BG Products NI Facebook Page'
                    />
                  </Nav.Link>
                  <Nav.Link onClick={() => {window.open('https://www.instagram.com/bgproducts_ni/')}}>
                    <img
                      src={instagramIcon}
                      width='20px'
                      height='20px'
                      className='d-inline-block align-top'
                      alt='BG Products NI Instagram Page'
                    />
                  </Nav.Link>
                  <Nav.Link onClick={() => {window.open('https://x.com/bgproductsni')}}>
                    <img
                      src={xIcon}
                      width='20px'
                      height='20px'
                      className='d-inline-block align-top'
                      alt='BG Products NI X Page'
                    />
                  </Nav.Link>
                  <Nav.Link onClick={() => {window.open('https://www.youtube.com/@bgproducts')}}>
                    <img
                      src={youtubeIcon}
                      width='20px'
                      height='20px'
                      className='d-inline-block align-top'
                      alt='BG Products NI YouTube Page'
                    />
                  </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container style={{color: 'black'}}>
        <Row style={{borderTop: '2px solid lightgray'}}>
          <Col style={{marginTop:'10px', marginBottom: '10px'}}>
            <a href='#contactUs'>
              <MyCarousel/>
            </a>
            <a href='#contactUs' style={{textDecoration:'none'}}>
              <button style={{fomtSize:'16px', fontWeight:'bold', borderRadius:'5px', padding:'12px 24px', margin:'auto', marginTop:'5px', display:'block', whiteSpace:'nowrap', overflow:'hidden', color:'black', backgroundColor:backgroundColor.current, transition: "all .5s ease" }}>Contact Us or Become a BG Partner</button>
            </a>
          </Col>
        </Row>
        <Row style={{borderTop: '2px solid lightgray'}} id='products'>
          <Col className='banner' style={{marginTop:'10px', marginBottom: '10px'}}>
            <h2>Products</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            {Products()}
          </Col>
        </Row>
        <Row style={{borderTop: '2px solid lightgray'}} id='reels'>
          <Col className='banner' style={{marginTop:'10px', marginBottom: '10px'}}>
            <h2>Reels</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Carousel 
              responsive={videoResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={1000}
              transitionDuration={1000}
            >
              {BgVideos()}
            </Carousel>
          </Col>
        </Row>
        <Row style={{borderTop: '2px solid lightgray'}} id='images'>
          <Col className='banner' style={{marginTop:'10px', marginBottom: '10px'}}>
            <h2>Images</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Carousel 
              responsive={imagesResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={1000}
              transitionDuration={1000}
            >
              {BgImages()}
            </Carousel>
          </Col>
        </Row>
        <Row style={{borderTop: '2px solid lightgray'}} id='reviews'>
          <Col className='banner' style={{marginTop:'10px', marginBottom: '10px'}}>
            <h2>Reviews</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Carousel 
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={1000}
              transitionDuration={1000}
            >
              {Reviews()}
            </Carousel>
          </Col>
        </Row>
        <Row style={{borderTop: '2px solid lightgray'}} id='contactUs'>
          <Col className='banner' style={{marginTop:'10px', marginBottom: '10px'}}>
            <h2>Contact Us</h2>
          </Col>
        </Row>
        <Row style={{color: 'white', background: '#444444', border: '2px solid #444444', borderRadius: '4px'}}>
          <Col className='details'>
            <div>
              <div className='details' style={{marginBottom: '20px'}}>
                  <img src={phoneIcon} alt='phone' style={{width: '30px', marginRight: '10px'}}/>
                  <div><a href='tel:+447872545149' style={{textDecoration:'none', color:'#FFDC00'}}>07872545149</a></div>
              </div>
              <div className='details'>
                <img src={emailIcon} alt='email' style={{width: '30px', marginRight: '10px'}}/>
                <div><a href='mailto:bgni@bgprod.ie' style={{textDecoration:'none', color:'#FFDC00'}}>bgni@bgprod.ie</a></div>
              </div>
            </div>
          </Col>
          <Col>
            <form onSubmit={handleSubmit} style={{paddingBottom: '20px'}}>
              <div style={{marginTop: '20px'}}>
                <input 
                  style={{width:'400px', height: '50px', marginBottom:'10px'}}
                  type="text"
                  onChange={(e) => {setFullName(e.target.value)}} 
                  value={fullName}
                  required
                  placeholder='Full Name'
                />
              </div>
              <div>
                <input 
                  style={{width:'400px', height: '50px', marginBottom:'10px'}}
                  type="email"
                  onChange={(e) => {setEmail(e.target.value)}} 
                  value={email}
                  required
                  placeholder='Email'
                />
              </div>
              <div>
                <textarea
                  style={{width:'400px', height: '150px', resize: 'none'}}
                  required
                  placeholder='Address'
                  onChange={(e) => {setAddress(e.target.value)}} 
                  value={address}
                />
              </div>
              <div>
                <input 
                  style={{width:'400px', height: '50px', marginBottom:'10px'}}
                  type="number"
                  onChange={(e) => {setPhone(e.target.value)}} 
                  value={phone}
                  required
                  placeholder='Phone'
                />
              </div>
              <div>
                <select style={{width:'400px', height: '50px', marginBottom:'10px'}} onChange={(e) => {setRequest(e.target.value)}} value={request}>
                  <option value={''}>I Want To</option>
                  <option value={'Request a workshop visit'}>Request a workshop visit</option>
                  <option value={'Request a workshop visit'}>Schedule a call</option>
                  <option value={'Request a workshop visit'}>Geneal Enquiry</option>
                  <option value={'Request a workshop visit'}>Become a BG Partner</option>
                </select>
              </div>
              <div>
                <textarea
                  style={{width:'400px', height: '150px', marginBottom:'10px', resize: 'none'}}
                  placeholder='Message Details'
                  
                  onChange={(e) => {setMessage(e.target.value)}} 
                  value={message}
                />
              </div>
              <div>
                <input type="submit" value={'SEND REQUEST'} disabled={!fullName || !email || !request || !message || loading }/>
              </div>
            </form>
          </Col>
        </Row>
        <footer>
          <Row style={{marginTop:'10px', marginBottom: '10px', borderTop: '2px solid lightgray'}} />
          <Row style={{color: 'white', textAlign: 'center'}}>
            <Col>
              Copyright 2024
            </Col>
          </Row>
        </footer>
      </Container>
    </div>
  );
}

export default App;